import { createGlobalStyle, ThemeProvider } from 'styled-components'
import '../styles/fonts.css'

const GlobalStyle = createGlobalStyle`



  body {
    ${tw`font-graphik`};
    // border-20 border-white
    color: ${props => (props.whiteColor ? 'white' : 'black')}
  }

  html {
    --darkthemeBackground: #262626;
    --darkthemeTextColor: #fff;
    --darkthemeSecondaryTextColor: #a5a5a5;
    --darkthemeLinkColor: #de751f;
    --darkthemeLinkColorHover: #f6993f;
    --lightthemeBackground: #f6f6f6;
    --lightthemeTextColor: #000;
    --lightthemeSecondaryTextColor: #a5a5a5;
    --lightthemeLinkColor: #de751f;
    --lightthemeLinkColorHover: #f6993f;
    --brightthemeBackground: #de751f;
    --brightthemeTextColor: #fff;
    --brightthemeSecondaryTextColor: #a5a5a5;
    --brightthemeLinkColor: #000;
    --brightthemeLinkColorHover: #333;
  }

  a {
    ${tw`no-underline transition-duration-200 transition-timing-ease-in`};
  }

  ::selection{
    ${tw`bg-grey-darkest text-white`};
 }
  
 [data-pagetheme='dark'],[data-blocktheme='black'] {
    ${tw`bg-grey-darkest text-white`};
    a{
      ${tw`text-orange hover:text-red`};
    }
    ::selection{
       ${tw`bg-orange text-black`};
    }
  }
  [data-blocktheme='light'] {
    ${tw`bg-grey-lightest text-black`};
    a{
      ${tw`text-orange hover:text-orange-dark`};
    }
  }
  [data-blocktheme='primary'] {
    ${tw`bg-orange text-white`};
    a{
      ${tw`text-white hover:text-orange-light`};
    }
    ::selection{
      ${tw`bg-white text-orange`};
   }
  }
`

export default GlobalStyle

// [data-blocktheme='light'] {
//   background-color: var(--lightthemeBackground);
//   color: var(--lightthemeTextColor);
// }
// [data-blocktheme='primary'] {
//   background-color: var(--brightthemeBackground);
//   color: var(--brightthemeTextColor);
// }
