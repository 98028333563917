import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import '../styles/style.css'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components'
import { ContextProviderComponent } from './context'
import GlobalStyle from '../components/globals'
import Header from '../components/Structure/Header.js'
// import Footer from '../components/footer.js'

const RouteContainer = posed.div({
	entering: { opacity: 0 },
	enter: {
		opacity: 1,
		delay: 0,
		transition: {
			opacity: { ease: 'easeOut', duration: 500 }
		}
	},
	exit: {
		opacity: 0,
		delay: 0,
		transition: {
			opacity: { ease: 'easeOut', duration: 150 }
		}
	}
})

// const AnimateNav = posed.nav({
// 	visible: { scale: 1 },
// 	hidden: { scale: 0.97 }
// })

const Main = styled.main`
	${tw`flex flex-col overflow-x-hidden min-h-screen relative antialiased font-graphik m-0 bg-grey-lightest`};
`

export default class Wrapper extends Component {
	render() {
		const { children, location, theme } = this.props

		return (
			<ContextProviderComponent>
				<Fragment>
					<PoseGroup preEnterPose="entering">
						<Header key="2" theme={theme.dark ? 'dark' : 'light'} />
						<RouteContainer key={location.pathname}>
							<GlobalStyle />
							<Main>{children}</Main>
						</RouteContainer>
					</PoseGroup>
				</Fragment>
			</ContextProviderComponent>
		)
	}
}

Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
	location: PropTypes.object.isRequired,
	theme: PropTypes.oneOf(['light', 'dark'])
}

Wrapper.defaultProps = {
	theme: 'light'
}
