import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'
// import Headroom from 'react-headroom'
import logo from '../../images/logo.svg'

const Header = ({ siteTitle, headerOvertop, navTheme, scrim }) => {
	const svgSource = `<svg viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg"><g id="pensa-logo"><path d="M59.167 59.268c-5.994 6.02-13.231 9.023-21.71 9.023-8.48 0-15.732-3.003-21.74-9.023-5.98-6.006-8.977-13.272-8.977-21.753 0-8.496 2.997-15.747 8.976-21.753 5.995-5.991 13.26-8.994 21.74-8.994 8.48 0 15.717 3.003 21.71 8.994 6.01 6.006 8.992 13.257 8.992 21.753s-2.997 15.747-8.991 21.753m4.722-48.238C56.579 3.72 47.763.073 37.44.073c-10.32 0-19.165 3.648-26.475 10.957C3.655 18.34 0 27.173 0 37.515c0 10.342 3.655 19.174 10.965 26.499C18.275 71.338 27.135 75 37.456 75c10.322 0 19.137-3.662 26.447-10.986 7.31-7.325 10.965-16.157 10.965-26.5-.014-10.341-3.67-19.174-10.98-26.484" id="circle"/><path id="line" d="M31.469 46.678h11.538V11.014H31.469z"/><path d="M37.238 49.825a7.343 7.343 0 1 1 0 14.686 7.343 7.343 0 0 1 0-14.686" id="dot"/></g></svg>`

	// const navstyles =
	// 	'block mt-4 md:inline-block md:mt-0 uppercase text-xs font-semibold tracking-wide trans mr-6 text-black hover:text-orange py-6'

	const PrimaryLinkHolder = styled.div`
		${tw`w-full hidden text-right flex-grow md:flex md:items-center md:w-auto`};

		a {
			${tw`block mt-4 md:inline-block md:mt-0 uppercase text-xs font-semibold tracking-wide trans mr-6 text-black hover:text-orange py-6`};

			&.activenav {
				${tw`text-orange`};
			}

			[data-navtheme='dark'] & {
				${tw`text-white hover:text-grey-light`};
			}
		}
	`

	// const NavLink = styled(Link)`
	// 	${tw`block mt-4 md:inline-block md:mt-0 uppercase text-xs font-semibold tracking-wide trans mr-6 text-black hover:text-orange py-6`};

	// 	[data-navtheme='dark'] & {
	// 		${tw`text-white hover:text-grey-light`};
	// 	}
	// `

	const LogoLink = styled(Link)`
		${tw`uppercase mx-auto md:mx-0 block text-center`};
		// stroke-current w-16 md:w-24 h-12

		[data-navtheme='dark'] & {
			${tw`text-red hover:text-purple-light`};
		}

		svg {
			${tw`w-12 h-12 fill-current text-orange hover:text-black`};
		}
	`

	const ListLink = props => (
		<Link
			// activeClassName="text-orange hover:text-orange activenav"
			to={props.to}
			getProps={({ isPartiallyCurrent }) =>
				isPartiallyCurrent ? { className: 'activenav' } : null
			}
		>
			{props.children}
		</Link>
	)

	const StyledHeader = styled.header`
		${tw`z-30 m-0 w-full`};
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background-image: ${scrim
				? `linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.15),
				rgba(0, 0, 0, 0) 100%
			)`
				: null};
		}
	`

	const Nav = styled.nav`
		${tw`flex items-center justify-between flex-wrap py-3 px-3 h-16 md:h-24 font-graphik relative z-40`};

		// [data-navtheme='dark'] & {
		// 	${tw`bg-purple`};
		// }
	`

	return (
		<StyledHeader
			className={headerOvertop ? 'relative' : 'relative md:absolute'}
			data-navtheme={navTheme || 'light'}
		>
			<Nav>
				<div className="flex items-center flex-grow md:flex-no-shrink md:mr-6">
					<LogoLink to="/">
						<InlineSVG src={svgSource} />
					</LogoLink>
				</div>

				<PrimaryLinkHolder>
					<div className="text-sm flex items-center ml-auto">
						<ListLink to="/work">Work</ListLink>
						<ListLink to="/process">Process</ListLink>
						<ListLink to="/about">About</ListLink>
						<ListLink to="/articles">Articles</ListLink>
						<ListLink to="/contact">Contact</ListLink>
					</div>
				</PrimaryLinkHolder>
			</Nav>
		</StyledHeader>
	)
}

export default Header

// <ListLink to="/careers">Careers</ListLink>
// <ListLink to="/pancakes">Pancakes</ListLink>
// <img src={logoKo} className="h-12 w-12" alt="Logo" />
